import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../layouts/index"
import Scrollspy from "react-scrollspy"

export default ({ data }) => {
  console.log(data)
  const navList = []
  data.allMarkdownRemark.edges.map(({ node }) => (
    navList.push(node.id)
  ))

  return (
    <Layout>
      <div className="solution-list">
        <div className="content-wrap">
          <Scrollspy
            items={navList}
            currentClassName="is-current"
          >
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <li
                key={node.id}
              >
                <Link to={"/solutions#" + node.id} className={"sol-type-" + node.frontmatter.scenes}>
                  {node.frontmatter.subtitle}
                </Link>
              </li>
            ))}
          </Scrollspy>
        </div>
      </div>
      <div className="content-wrap">
        <div className="solution-cases">
          <div className="solution-main">
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <div key={node.id} id={node.id} className="solution-item">
                <h1>{node.frontmatter.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: node.html }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "solution" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          html
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            author
            scenes
            subtitle
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
